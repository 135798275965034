@import '../../styles/constants.scss';

.contact {
  background-color: $secondary;

  .content {
    display: flex;
    flex-direction: row;

    .imageContent {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      >img {
        width: 100%;
        height: 400px;
      }
    }

    .formContent {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .form {
        display: flex;
        flex-direction: column;
        gap: $size-lg;
        width: 100%;
        padding: 0 $size-xl;

        >input,
        .row1>input,
        .row2>input,
        .textarea>textarea {
          padding: $size-md;
          width: 100%;
          border: $size-xs solid $white;

          &::placeholder {
            font-size: $font-sm;
          }

          &.error {
            border: $size-xs solid $color-error-lighter;
          }
        }

        #label>input {
          appearance: unset;
          border: 1px solid $black;
          border-radius: 8px;
          background-color: $white;

          &.error {
            border: $size-xs solid $color-error-lighter;
          }
        }

        #label>input:checked {
          background-color: $color-success;
        }

        .textarea>textarea {
          resize: none;
        }

        .row1,
        .row2 {
          display: flex;
          flex-direction: row;
          gap: $size-lg;
        }
      }

      >button {
        margin-right: $size-xl;
        padding: $size-sm $size-lg;
        font-weight: bold;
        margin-left: auto;
        border: unset;
        background-color: $white;
        margin-top: $size-lg;
        margin-bottom: $size-lg;
      }

      #agreement {
        width: 15px;
        height: 15px;
      }

      #label {
        display: flex;
        gap: $size-sm;
      }

      .error-message {
        padding: $size-md;
        border-radius: $size-md;
        border: 3px solid $color-error-lighter;
      }

      .success-message {
        margin-top: $size-md;
        padding: $size-md;
        border-radius: $size-md;
        border: 3px solid $color-success;
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .contact {
    .content {
      flex-direction: column;
      gap: $size-xl;

      .imageContent {
        width: 100%;
      }

      .formContent {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .contact {
    .content {
      .formContent {
        .form {
          padding: 0;

          .row1,
          .row2 {
            flex-direction: column;
          }
        }
      }
    }
  }
}