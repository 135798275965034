.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #343a40;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  transition: transform 0.3s ease;
  z-index: 1000;

  .sidebar-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #343a40;
    position: relative;

    .sidebar-logo {
      img {
        width: 50px;
        height: auto;
      }
    }

    .sidebar-toggle {
      background: none;
      border: none;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .sidebar-menu {
    width: 100%;
    margin-top: 20px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        width: 100%;
        text-align: center;

        a {
          display: block;
          padding: 15px;
          color: #fff;
          text-decoration: none;
          width: 100%;

          &:hover {
            background-color: #495057;
          }
        }
      }

      .mobile-only {
        display: none;
      }
    }
  }

  &.open .sidebar-menu {
    display: block;
  }

  .logout-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 20px;

    &.desktop-only {
      display: block;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    position: relative;

    .sidebar-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      margin-top: 0;

      &.open {
        display: flex;
      }

      ul .mobile-only {
        display: block;
      }
    }

    .sidebar-topbar {
      justify-content: space-between;
      padding: 10px 20px;

      .sidebar-logo {
        img {
          width: 40px;
          height: auto;
        }
      }

      .sidebar-toggle {
        display: block;
      }
    }

    .logout-button.desktop-only {
      display: none;
    }
  }
}
