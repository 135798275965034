@import '../../styles/constants.scss';
.textos-page {
  display: flex;

  .content {
    margin-left: 250px; /* Mismo ancho que la barra lateral */
    padding: 20px;
    width: calc(100% - 150px);

    h1 {
      margin-bottom: 20px;
      font-size: 24px;
      color: #333333;
    }
    
    .section-header {
      display: flex;
      align-items: center;
      padding-bottom: $size-lg;
    }
    
    .section-header h2 {
      margin-right: 20px;
      font-size: 24px;
      color: #333333;
    }
    
    .section-header button {
      padding: 10px 20px;
      background-color: #333333;
      color: #ffffff;
      border: none;
      border-radius: 6px;
      cursor: pointer;
    }
    
    .section-header button:hover {
      background-color: #555555;
    }

    .section-title {
      font-size: 20px;
      margin-bottom: 15px;
      color: #444444;
    }

    .homepage-video, .how-we-work-box, .pack-box, .text-box {
      background: #ffffff;
      padding: 20px;
      margin-bottom: 30px;
      border-radius: 6px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .textarea-container {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }

      textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 14px;
        resize: vertical;
      }
    }

    .price-container {
      display: flex;
      align-items: center;

      label {
        margin-right: 15px;
        font-size: 16px;
        color: #333333;
      }

      input {
        padding: 8px;
        border-radius: 4px;
        font-size: 14px;
        color: #333333;
        border: 1px solid #cccccc;
        width: 100px;
      }
    }

    .dropzone {
      width: 100%;
      padding: 15px;
      border: 2px dashed #cccccc;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 20px;

      p {
        margin: 0;
        color: #666666;
        font-size: 14px;
      }
    }

    .uploaded-image {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
      margin-top: 10px;
    }

    .homepage-video {
      position: relative;

      label {
        display: block;
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }

      .video-container {
        position: relative;
      }

      video {
        max-width: 100%;
        height: auto;
        border-radius: 6px;
      }

      input[type="file"] {
        display: none;
      }

      .change-video-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 8px 15px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #555555;
        }
      }
    }
  }
}

.language-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    margin-right: 15px;
    font-size: 16px;
    color: #333333;
  }

  select {
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    border: 1px solid #cccccc;
  }
}

@media (max-width: 768px) {
  .textos-page {
    flex-direction: column;
    .content {
      margin-left: 0;
      padding: 20px;
      width: 100%;
    }
  }
}
