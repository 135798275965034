// ***********
// COLORS
// ***********
// Primary
$primary: #56AF33;
$primary-dark: darken($primary,10);
$primary-light: lighten($primary,10);
$primary-darker: darken($primary-dark,10);
$primary-lighter: lighten($primary-light,10);

// Secondary
$secondary: #FFC800;
$secondary-dark: darken($secondary,10);
$secondary-light: lighten($secondary,10);
$secondary-darker: darken($secondary-dark,10);
$secondary-lighter: lighten($secondary-light,10);

// Tertiary
$tertiary: #FF6400;
$tertiary-dark: darken($tertiary,10);
$tertiary-light: lighten($tertiary,10);
$tertiary-darker: darken($tertiary-dark,10);
$tertiary-lighter: lighten($tertiary-light,10);

// Accent
$accent: #FFFAE1;
$accent-dark: darken($accent,10);;
$accent-light: lighten($accent,10);;
$accent-darker: darken($accent-dark,10);;
$accent-lighter: lighten($accent-light,10);;

// Gray
$gray: #888888;
$gray-dark: darken($gray,10);;
$gray-light: lighten($gray,10);
$gray-darker: darken($gray-dark,10);;
$gray-lighter: lighten($gray-light,10);
$gray-border: 1px solid rgba($gray-light, 0.5);

// B&W
$background-gray: #f4f4f4;
$background-black-light: #3C3C3C;
$white: #ffffff;
$black: #282828;

// Error, info...
$color-error: #db341a;
$color-error-lighter: #ff0000;
$color-error-border: #ff745e;
$color-warning: #e8b125;
$color-warning-border: #ffef5e;
$color-info: $primary;
$color-info-border: $primary-light;
$color-success: #699c1c;
$color-success-border: #acde5b;

// ***********
// PADDING, MARGIN AND RADIUS
// ***********
$size-xs: 2px;
$size-sm: 4px;
$size-md: 8px;
$size-lg: 16px;
$size-xl: 32px;
$size-xxl: 64px;

$content-max-width: 1500px;

// ***********
// TRANSITIONS
// ***********
$transition-very-slow: 1s;
$transition-slow: 0.5s;
$transition-mid: 0.25s;
$transition-fast: 0.1s;

// ***********
// FONT
// ***********.
$font-xxs: 12px;
$font-xs: 14px;
$font-sm: 16px;
$font-sm-mid: 18px;
$font-md: 20px;
$font-lg: 24px;
$font-xl: 32px;
$font-subtitle: 40px;
$font-title: 50px;
$font-title-xl: 60px;
$font-title-xxl: 70px;


$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: bold;
$weight-black: 900;


// ***********
// SCREEN SIZES
// ***********
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
