@import '../../styles/constants.scss';

.pack {
  .content {
    width: 100%;
    height: 100%;

    >h2 {
      color: $white;
      -webkit-text-stroke: 1px $primary;
      font-size: $font-title;
      margin-bottom: $size-lg;

      >span {
        color: $primary;
        font-family: 'Neuebit', 'sans-serif';
        letter-spacing: $size-md;
        font-size: $font-title-xxl;
        font-weight: bold;
      }
    }

    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $size-lg;
      height: 100%;
      width: 100%;
      justify-content: center;

      .card {
        flex: 1;
        min-width: 250px;
        max-width: 300px;
        margin-top: $size-lg;

        // all the content at card
        >div {
          display: flex;
          flex-direction: column;
          gap: $size-lg;
        }

        // all at card h3
        >div>h3,
        >div>div>h3 {
          margin: 0;
          padding-bottom: $size-lg;
          font-size: $font-md;
          font-weight: 900;
          letter-spacing: 0.7px;
        }

        // first card
        .installation {
          padding-bottom: $size-xl;
        }

        // first card
        .subscription {
          margin-top: auto;
        }

        // all the ul and li at card
        >div>ul,
        >div>div>ul {
          padding-inline-start: $size-lg;

          >li {
            padding: $size-md;
          }
        }

        .list {
          >li:last-of-type {
            margin-top: $size-xl;
          }
        }

        // class at card
        .messageText {
          margin-top: auto;
          color: $secondary;
        }

        // class at card
        .priceText {
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width:$screen-md) {
  .pack {
    .content {
      >h2 {
        font-size: $font-subtitle;

        >span {
          font-size: $font-title;
        }
      }

      .cards {
        flex-wrap: nowrap;
        flex-direction: column;

        .card {
          min-width: unset;
          max-width: unset;
          width: 100%;
        }
      }
    }
  }
}