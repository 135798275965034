@import '../../../../styles/constants.scss';


.presentatCardConent {
  width: 100%;
  height: 100%;
  margin-bottom: $size-lg;
  display: flex;
  gap: $size-xl;

  &.reverse {
    flex-direction: row-reverse;
  }

  .imageCardWrapper {
    width: 50%;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contentWrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: $size-lg;

    .titleCardWrapper {
      width: 100%;
      text-align: center;

      >h3 {
        font-size: $font-subtitle;
        font-weight: 900;
        color: rgba($white, 0.5);
        -webkit-text-stroke-width: 2px;

        >span {
          font-family: 'Neuebit', 'sans-serif';
          font-size: $font-subtitle + 15px; // font max weight is bold
          font-weight: bold;
        }

        &.primary {
          -webkit-text-stroke-color: $primary;
        }

        &.secondary {
          -webkit-text-stroke-color: $secondary;
        }

        &.tertiary {
          -webkit-text-stroke-color: $tertiary;
        }

        &.accent {
          -webkit-text-stroke-color: $accent-darker;
        }
      }
    }

    .textWrapper {
      width: 100%;
      height: auto;
      color: $black;
      margin: auto;

      >div>div {
        display: flex;
        flex-direction: column;
        gap: $size-lg;
      }

      >div>div>p {
        font-size: $font-sm-mid;
        line-height: $font-lg;
      }
    }

    .messageWrapper {
      width: 100%;
      max-width: 250px;
      margin-left: auto;
      color: $tertiary;
      display: flex;
      flex-direction: column;
      gap: $size-xs;

      >p {
        color: $tertiary;
        font-weight: 500;
      }

      >p:first-of-type {
        font-size: $font-title;
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .presentatCardConent {
    .contentWrapper {
      .titleCardWrapper {
        >h3 {
          font-size: $font-xl;

          >span {
            font-size: $font-xl + 12px; //font max weight is bold
          }
        }
      }

      .messageWrapper {
        >p:first-of-type {
          font-size: $font-xl;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .presentatCardConent {
    flex-direction: column;

    &.reverse {
      flex-direction: column;
    }

    .imageCardWrapper {
      width: 100%;
    }

    .contentWrapper {
      width: 100%;

      .titleCardWrapper {
        >h3 {
          font-size: $font-xl;

          >span {
            font-size: $font-xl + 12px; //font max weight is bold
          }
        }
      }

      .textWrapper {
        >div>div>p {
          font-size: $font-sm;
        }
      }

      .messageWrapper {
        >p:first-of-type {
          font-size: $font-xl;
          line-height: $font-md;
        }
      }
    }
  }
}

@media screen and (max-width:$screen-sm) {
  .presentatCardConent {
    .contentWrapper {
      .titleCardWrapper {
        >h3 {
          font-size: $font-lg;

          >span {
            font-size: $font-lg + 9px; //font max weight is bold
          }
        }
      }

      .messageWrapper {
        >p:first-of-type {
          font-size: $font-lg;
        }
      }
    }
  }
}