//METROPOLIS
// Thin
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-Thin.woff2') format('woff2');
}

// Extra Light
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-ExtraLight.woff2') format('woff2');
}

// Light
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-Light.woff2') format('woff2');
}

//Regular
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-Regular.woff2') format('woff2');
}

//Medium
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-Medium.woff2') format('woff2');
}

//Semi Bold
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-SemiBold.woff2') format('woff2');
}

//Bold
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-Bold.woff2') format('woff2');
}

//ExtraBold
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-ExtraBold.woff2') format('woff2');
}

// Black
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../assets/fonts/Metropolis/Metropolis-Black.woff2') format('woff2');
}

//HATTON
// UltraLight
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../assets/fonts/Hatton/Hatton-Ultralight.woff2') format('woff2');
}

// Light
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/Hatton/Hatton-Light.woff2') format('woff2');
}

//Medium
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/Hatton/Hatton-Medium.woff2') format('woff2');
}

//Bold
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Hatton/Hatton-Bold.woff2') format('woff2');
}

//UltraBold
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../assets/fonts/Hatton/Hatton-Ultrabold.woff2') format('woff2');
}

//NEUEBIT
//Regular
@font-face {
  font-family: 'Neuebit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts//Neuebit/NeueBit-Regular.woff2') format('woff2');
}

//Bold
@font-face {
  font-family: 'Neuebit';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../assets/fonts//Neuebit/NeueBit-Bold.woff2') format('woff2');
}