@import '../../styles/constants.scss';

.section {
  width: 100%;
  padding: $size-xl;

  .content {
    width: 100%;
    margin: 0 auto;
    max-width: $content-max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}