@import '../../styles/constants.scss';

.layer-card {
  position: relative;
  padding-right: $size-lg;
  padding-bottom: $size-lg;
  &:hover {
    .layer1 {
      transform: scale(1.01);
    }
  }

  .layer1 {
    transition: all linear $transition-mid;
    width: 100%;
    padding: $size-xl;
    height: 100%;
  }

  .layer2,
  .layer3 {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    padding: $size-md $size-lg;
    position: absolute;
    z-index: -1;
    top: 0;
  }

  .layer2 {
    z-index: -1;
    transform: translate($size-md, $size-md);
  }

  .layer3 {
    z-index: -2;
    transform: translate($size-lg, $size-lg);
  }
}

@media screen and (max-width: $screen-lg) {
  .layer-card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.isPack {
      .layer1 {
        min-height: 400px;
      }
    }
  }
}