@import '../../../styles/constants.scss';

.top-section {
  width: 100%;
  height: 100vh;
  margin-top: $size-xxl;

  >video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width:$screen-sm) {
  .top-section {
    margin-top: 0;
  }
}