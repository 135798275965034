@import '../../styles/constants.scss';

.footer {
  background-color: $background-black-light;

  .contentFooterWrapper {
    width: 100%;
    display: flex;
    padding: $size-xl $size-xxl;

    .imageContent {
      width: 50%;

      >img {
        height: 400px;
      }

      .textContent {
        color: $white;

        >p:first-of-type {
          padding: $size-xl 0;
        }

        >p:not(:first-of-type) {
          padding: $size-xs 0;
        }
      }
    }

    .linksContent {
      width: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .socialMedia {
        display: flex;
        gap: $size-xl;

        >a {
          border-radius: $size-xl;
          padding: $size-md;
          background-color: $tertiary;
          display: flex;
          align-items: center;
          justify-content: center;

          >img {
            width: 37px;
            height: 37px;
          }
        }
      }
    }
  }

  .kitDigitalWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid $background-gray;
    margin-top: $size-lg;
    padding-top: $size-lg;
    background-color: $black;

    >h4 {
      color: $white;
      margin: 0;
      text-align: center;
      font-weight: 600;
    }

    .kitDigitalImagesWrapper {
      gap: $size-lg;
      display: flex;

      >img {
        width: 200px;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .footer {

    .contentFooterWrapper {
      flex-direction: column;
      padding: $size-xl;
      gap: $size-xl;

      .imageContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >img {
          width: 100%;
          height: 100%;
          max-width: 400px;
          max-height: 400px;
        }
      }

      .linksContent {
        width: 100%;
        align-items: center;
        justify-content: center;

        .socialMedia {
          >a {
            >img {
              width: 26px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .footer {
    .kitDigitalWrapper {
      margin-top: $size-lg;
      padding: $size-xl;

      .kitDigitalImagesWrapper {
        >img {
          width: 150px;
        }
      }
    }
  }
}