@import '../../styles/constants.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $size-xxl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $accent;
  z-index: 100000;

  >a img {
    height: $size-xl;
    margin-left: $size-xl;
  }

  .links {
    margin-right: $size-xl;
    display: flex;

    > button,
    > a {
      border: none;
      color: $primary;
      background-color: unset;
      font-weight: bold;
      font-size: $font-sm;
      text-decoration: none;
      cursor: pointer;
      padding: $size-md; /* Ajusta el relleno según sea necesario */
    }
  }

  .menu-icon {
    display: none;
  }
}

@media screen and (max-width: $screen-sm) {
  .navbar {

    .links {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      overflow: hidden;

      &.open {
        height: fit-content;
        margin-top: $size-xxl;
        flex-direction: column;
        background-color: $white;
        transition: all $transition-mid;
        background-color: $accent;

        > button,
        > a {
          width: 100%;
          height: $size-xxl;
          border-top: 1px solid $primary;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        > select {
          margin-bottom: $size-lg;
        }
      }
    }

    .menu-icon {
      display: flex;
      border: none;
      background: none;
      margin-right: $size-xl;

      > img {
        width: $size-xl;
      }
    }
  }
}
