@import '../../styles/constants.scss';
.contactPage {
  padding-top: $size-xxl;
  h1 {
    background-color: $secondary;
    font-size: $font-title;
    text-align: center;
    padding: $size-xxl;
    color: $white;
  }
}

@media screen and (max-width:$screen-sm) {
  .contactPage {
    h1 {
      padding: $size-md;
      // font-size: $font-subtitle;
    }
  }
}