@import '../../../styles/constants.scss';

.aboutUs {
  width: 100%;

  .content {
    width: 100%;

    >h2 {
      color: $white;
      -webkit-text-stroke: 1px $primary;
      font-size: $font-title;
      margin-bottom: $size-lg;

      >span {
        color: $primary;
        font-family: 'Neuebit', 'sans-serif';
        letter-spacing: $size-md;
        font-size: $font-title-xxl;
        font-weight: 800;
      }
    }

    .cards {
      display: flex;
      flex-direction: row;
      gap: $size-lg;
      height: 100%;

      .card {
        flex: 1;

        // all at card h3
        >div>h3 {
          margin: 0;
          padding-bottom: $size-lg;
          font-size: $font-subtitle;
          font-family: 'Neuebit', 'sans-serif';
        }

        .firstPharagraph {
          >p {
            margin-bottom: $size-xl;
          }
        }

        .secondPharagraph {
          >p {
            margin-bottom: $size-md;
          }

          >p:last-child {
            margin-top: $size-lg;
          }
        }

        // all ul at card
        >div>ul {
          padding-inline-start: $size-lg;
        }

        // all li at card
        >div>ul>li,
        >div>div>ul>li {
          padding: $size-sm;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .aboutUs {
    .content {
      .cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $size-xl;

        .card {
          flex: none;
        }
      }
    }
  }
}

@media screen and (max-width:$screen-sm) {
  .aboutUs {
    .content {
      >h2 {
        font-size: $font-subtitle;

        >span {
          font-size: $font-title;
        }
      }
    }
  }
}