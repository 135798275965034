@import '../../../styles/constants.scss';
@import '../../../styles/animations.scss';

.presentation {
  .content {
    gap: $size-lg;

    .titleWrapper {
      align-self: center;
      margin: $size-lg 0;

      >h2 {
        max-width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        >p {
          padding-bottom: $size-sm;
          font-size: $font-title;
          font-family: 'Hatton', 'Georgia';
          width: fit-content;
          position: relative;
          white-space: nowrap;
        }
      }

      >h2>p:nth-child(1):before {
        background-color: $primary;
      }

      >h2>p:nth-child(2):before {
        background-color: $tertiary;
        transform-origin: bottom left;
      }

      >h2>p:nth-child(3):before {
        background-color: $accent-darker;
      }

      >h2>p:before {
        content: '';
        position: absolute;
        width: 100%;
        height: $size-sm;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform ease-in $transition-very-slow;
        border-radius: $size-sm;
      }
    }

    .titleWrapper.activeAction {
      >h2>p:nth-child(2):before {
        background-color: $tertiary;
        transform-origin: bottom right;
      }

      >h2>p:before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    .titleWrapper:hover {
      >h2>p:before {
        transform: scaleX(0);
        transform-origin: bottom left;
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .presentation {
    .content {
      .titleWrapper {
        >h2 {
          max-width: 80vw;

          >p {
            font-size: $font-subtitle;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .presentation {
    .content {
      .titleWrapper {
        >h2 {
          max-width: 100%;

          >p {
            font-size: $font-subtitle;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .presentation {
    .content {
      .titleWrapper {
        >h2 {
          >p {
            font-size: $font-lg;
          }
        }
      }
    }
  }
}