@import '../../styles/constants.scss';


.languageSelector {
  .wozzo-dropdown {
    >.btn {
      color: $primary;
      font-size: $font-sm;

      &:hover {
        box-shadow: none !important;
      }

      >i {
        font-size: $font-sm;
      }
    }
  }
}