.contacts-backoffice-page {
  display: flex;

  .content {
    margin-left: 250px; /* Same width as the sidebar */
    padding: 20px;
    width: 100%;

    h1 {
      margin-bottom: 20px;
    }

    .contacts-table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        padding: 10px;
        border: 1px solid #ddd;
        text-align: left;
      }

      th {
        background-color: #f4f4f4;
      }

      tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
      }

      tbody tr:hover {
        background-color: #f1f1f1;
      }
    }
  }
}


@media (max-width: 768px) {
  .contacts-backoffice-page {
    flex-direction: column;
    .content {
      margin-left: 0;
      overflow: scroll;
    }
  }
}